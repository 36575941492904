<template>
  <div id="hero-container-expanded-center">
    <div class="grid-container-fromLeft" :style="{ backgroundImage: 'url(' + backgroundContainer + ')'}">
      <div class="textAndImage">
        <div class="grid-container-textAndImage">
          <div class="textAndImage__content">
            <div class="textAndImage__block">
              <div class="textAndImage_block-text">
                <div class="textAndImage__heading">
                  <h2 class="textAndImage__title">We develop</h2>
                </div>

                <p> With expertise in WordPress, Magento, Joomla, Ruby and custom built content management systems, we build tools to help automate and bring your business into the 21st century.
                </p>

                <div class="button-request">
                  <button class="button-request__content"
                          v-on:click.prevent="showCalendy()">
                    Let&#39;s talk about your project
                  </button>
                  <slideout-panel></slideout-panel>
                </div>
                <div class="button-request-line"></div>
              </div>
              <div class="textAndImage__block-image"></div>
            </div>
          </div>
        </div>
      </div>


      <div class="container-design-flume" >
        <div class="container-design-flume__content">
            <div class="container-design-titles">
              <div class="container-design_title">Your image matters</div>
              <div class="container-design_subtitle">...we design!</div>
            </div>

        <div class="container-design-flume__block">
            <div class="container-design-flume__block-textAndButton">
              <p>Your vision and ideas will be designed<br>
                in perfect detail no matter how <br>
                laborious they are.
              </p>
              <div class="container-design-btn_portfolio">
                <router-link :to="{ name: 'Portfolio' }">
                  <button class="button-portfolio" type="button">
                    View portfolio
                  </button>
                </router-link>
              </div>
            </div>

            <div class="container-design-flume__block-laptopImage">
              <img :src="laptopDesign" alt="Laptop Design" width="100%" height="100%">
            </div>
          </div>
      </div>
      </div>
      <div class="container-separator-line"></div>

        <div class="container-support">
        <div class="container-support-titles">
          <div class="container-support-titles-grid">
            <div class="container-support_title">We support</div>
            <div class="container-support_subtitle">Your problems won't go unsolved</div>
          </div>
        </div>
        <div class="container-support-connections">
          <div class="supportArchitects" :style="{ backgroundImage: 'url(' + weSupportConnections + ')'}">
            <div class="container-support-grid">
              <div class="container-support-architects">
                <div id="container-support-architects-title"></div>
                <div class="target">|</div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-support-text">
          <div class="container-support-text-grid">
            <div class="container-support-text__content">
              <p>We support companies that activate in the financial, technology and <br>
                public sector. Our team of system administrators can help you keep <br>
                your environment stable while providing monitoring for your servers <br>
                and making sure that you don't have to worry about keeping your <br>
                systems online but rather focus on selling your products.
              </p>
            </div>

            <div class="container-support__button">
              <router-link :to="{ name: 'Support'}" target='_blank'>
                <button class="button-learn-more" type="button">Learn more</button>
              </router-link>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>

import $ from "jquery";
import 'jquery-ui/ui/effects/effect-pulsate'

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import backgroundContainer from '../../assets/flume-bg-dark-short.png';
import mockDesign from '../../assets/mock-desing.png';
import supportArchitects from '../../assets/wesupport-connections-blue.svg';

export default {
  name: "ExpertiseExpanded",
  data() {
    return {
      backgroundContainer: backgroundContainer,
      laptopDesign: mockDesign,
      weSupportConnections: supportArchitects,
    }
  },
  mounted() {
    $(document).on('scroll', function () {
      function isScrolledIntoView(elem) {
        let docViewTop = $(window).scrollTop();
        let docViewBottom = docViewTop + $(window).height();

        let nav = $('#container-support-architects-title');

        if (nav.length) {
          var elemTop = nav.offset().top;
        }

        var elemBottom = elemTop + $(elem).height();
        return ((elemBottom >= docViewTop) && (elemTop <= docViewBottom) && (elemBottom <= docViewBottom) && (elemTop >= docViewTop));
      }

      if (isScrolledIntoView($('#container-support-architects-title'))) {
        setTimeout(type, 500);
        $('.target').effect('pulsate', {times: 1000}, 999999)
      }
    });

    //type-writer animation 'Architects' from support header
    let str = "<p>Architects</p>",
        i = 0,
        isTag,
        text;
    function type() {
      text = str.slice(0, ++i);
      if (text === str) return;

      document.getElementById('container-support-architects-title').innerHTML = text;

      let char = text.slice(-1);
      if (char === '<') isTag = true;
      if (char === '>') isTag = false;
      if (isTag) return type();
    }

    //animation lib with magic scroll expanded container
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.saveStyles(".mobile, .desktop");

    ScrollTrigger.matchMedia({
      //desktop
      "(min-width: 800px)": function () {
        // gsap.set('.grid-container-fromLeft', {xPercent: -50, yPercent: 0})
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: "#hero-container-expanded-center",
            toggleActions: "play         none        reverse        none",
            //              onEnter      onLeave     onEnterBack    onLeaveBack
            pin: "#hero-container-expanded-center",
            start: "5% center",
            end: "+=10%",
            scrub: false,
            markers: false,
          },
          defaults: {duration: 0.5, ease: 'none'}
        });
        tl.from('.grid-container-fromLeft', {xPercent: -50})
        tl.to('.grid-container-fromLeft', {width: '100%'})
        tl.to({}, {duration: 1})
      },

      //mobile
      "(max-width: 799px)": function () {

        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: "#hero-container-expanded-center",
            toggleActions: "play         none        reverse        none",
            //              onEnter      onLeave     onEnterBack    onLeaveBack
            pin: "#hero-container-expanded-center",
            pinnedContainer: "#hero-container-expanded-center",
            start: "5% center",
            end: "+=15%",
            scrub: false,
            markers: false,
          },
          defaults: {duration: 0.5, ease: 'none'}
        });
        tl.from('.grid-container-fromLeft', {xPercent: -50})
        tl.to('.grid-container-fromLeft', {width: '100%'})
        tl.to({}, {duration: 0.5})
      },
    })


  },
  methods: {
    showCalendy() {
      const panel = this.$showPanel({
        component: "Panel",
        cssClass: "Panel",
        cssClass: "calendar-panel",
        props: {}
      });
    },
  }
}
</script>

<style scoped lang="scss">

#hero-container-expanded-center {
  max-height: 100%!important;
  position: relative;
  height: 100%;
  width: 100%;
}

.grid-container-fromLeft {
  position: relative;
  width: 85%;
  background-repeat: no-repeat;
background-size: 150%;
  background-position: top;
  background-color: #ffffff;
  webkit-box-shadow: 1px 6px 80px 8px rgba(0, 0, 0, .5);
  -moz-box-shadow: 1px 6px 80px 8px rgba(0, 0, 0, .5);
  box-shadow: 1px 6px 80px 8px rgba(0, 0, 0, .5);
}

.grid-container-fromLeft:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 5px;
  background: rgb(47, 152, 200);
  background: -moz-linear-gradient(90deg, rgba(47, 152, 200, 1) 26%, rgba(203, 5, 107, 1) 96%);
  background: -webkit-linear-gradient(90deg, rgba(47, 152, 200, 1) 26%, rgba(203, 5, 107, 1) 96%);
  background: linear-gradient(90deg, rgba(47, 152, 200, 1) 26%, rgba(203, 5, 107, 1) 96%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2f98c8", endColorstr="#cb056b", GradientType=1);
  top: 0;
  left: 0;
}

.container-design_titles_grid,
.container-support-titles-grid,
.container-support-text {
  position: relative;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.textAndImage {
  padding: 5em 0 0 0;
  max-height: 100vh;
}

.grid-container-textAndImage {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  overflow: hidden;
}

.textAndImage__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  line-height: 2em;
}

.textAndImage__block {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  width: 80em;
}

.textAndImage__heading {
  display: block;
  font-weight: 700;
  font-size: 26pt;
  margin-bottom: 25px;
  text-align: right;
  line-height: 2em;
}

.textAndImage__title {
  background: rgb(45, 152, 200);
  background: -moz-linear-gradient(125deg, rgba(45, 152, 200, 1) 65%, rgba(26, 48, 65, 1) 96%);
  background: -webkit-linear-gradient(125deg, rgba(45, 152, 200, 1) 65%, rgba(26, 48, 65, 1) 96%);
  background: linear-gradient(125deg, rgba(45, 152, 200, 1) 65%, rgba(26, 48, 65, 1) 96%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2d98c8", endColorstr="#1a3041", GradientType=1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.textAndImage__content {
  text-align: right;
  font-size: 25px;
  line-height: 1.4;
  color: #838483;
}


.textAndImage__block > :first-child {
  width: calc(40% - 2px);
  padding-right: 10%;
  margin-top: 70px;
}

.textAndImage__block > :last-child {
  background: url("../../assets/screens-wedevelop.png") no-repeat;
  background-size: contain;
  position: relative;
  height: 31em;
  width: calc(28% - 2px);
  z-index: 2048;
}

.button-request-line {
  position: relative;
  z-index: 101;
  width: 9em;
  bottom: 21px;
  left: 100%;
  background-color: #2d98c8;
  height: 1px;
}

.button-request {
  margin-top: 30px;
}

.button-request__content {
  font-size: 25px;
  display: inline-block;
  border-radius: 99px;
  z-index: 1;
  margin: 0;
  position: relative;
  text-align: center;
  color: #2d98c8;
  padding: 10px 100px;
}

.button-request__content:before, .button-request__content:after {
  content: ' ';
  position: absolute;
  border-radius: 99px;
}

.button-request__content:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
  background: #FFFFFF;
  background: -moz-linear-gradient(right, #cd1677 0%, #2d98c8 100%);
  background: -webkit-gradient(linear, right top, left top, color-stop(0%, #cd1677), color-stop(100%, #2d98c8));
  background: -webkit-linear-gradient(right, #cd1677 0%, #2d98c8 100%);
  background: -o-linear-gradient(right, #cd1677 0%, #2d98c8 100%);
  background: -ms-linear-gradient(right, #cd1677 0%, #2d98c8 100%);
  background: linear-gradient(to right, #cd1677, #2d98c8 99%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cd1677', endColorstr='#2d98c8', GradientType=1);
}

.button-request__content:after {
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  background-color: #FFFFFF;
  z-index: -1;
  opacity: 1;
  transition: all 0.6s ease-in-out;
}

.button-request__content:hover:after {
  opacity: 0;
}

.button-request__content:hover {
  color: #FFFFFF;
  transition: all 0.5s ease;
  background: -moz-linear-gradient(right, #cd1677 0%, #2d98c8 100%);
  background: -webkit-gradient(linear, right top, left top, color-stop(0%, #cd1677), color-stop(100%, #2d98c8));
  background: -webkit-linear-gradient(right, #cd1677 0%, #2d98c8 100%);
  background: -o-linear-gradient(right, #cd1677 0%, #2d98c8 100%);
  background: -ms-linear-gradient(right, #cd1677 0%, #2d98c8 100%);
  background: linear-gradient(to right, #cd1677, #2d98c8 99%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cd1677', endColorstr='#2d98c8', GradientType=1);
}

.container-design-flume {
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
  background-size: cover;
}
.container-design-flume__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  line-height: 2em;
}

.container-design-flume__block {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.container-design_title, .container-design_subtitle {
  font-weight: 700;
  text-align: center;
  line-height: 2em;
}

.container-design-titles {
  max-width: 1400px;
  width: 22%;
  margin: 0 auto;
  position: relative;
  padding-top: 60px;
  padding-left: 10px;
  padding-right: 10px;
}

.container-design_title {
  font-size: 50px;
  background: rgb(57, 159, 203);
  background: linear-gradient(133deg, #399fcb 43%, #b33792 52%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.container-design_subtitle {
  font-size: 22px;
  padding-right: 58%;
  background: linear-gradient(133deg, #cd1677 33%, #9c22b7 38%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;

}

.container-design-flume__block > :first-child {
  width: 40%;
  margin-top: 60px;

}
.container-design-flume__block > :last-child {
  width: 40%;
  margin-top: 60px;

  img{
    width: 100%;
  }
}

.container-design-flume__block-textAndButton p,
.button-portfolio {
  color: #eaf1f4;
  line-height: 2em;
  font-size: 1.5em;
  text-align: right;
}
.button-portfolio {
  z-index: 101;
  margin-top: 40px;
  left: 26%;
  position: relative;
  text-align: center;
  padding: 5px 100px 5px 100px;
  border-radius: 99px;
  color: #eaf1f4;
  display: inline-block;
  overflow: hidden;
  border: solid 1px #eaf1f4;
  background: rgba(255, 255, 255, 0);

}

.button-portfolio:after {
  content: ' ';
  position: absolute;
  border-radius: 99px;
  content: ' ';
  position: absolute;
  border-radius: 99px;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  background: #040404;
  background: -moz-linear-gradient(right, rgba(186, 57, 83, 1) 0%, rgba(186, 57, 83, 0.6965161064425771) 100%);
  background: -webkit-gradient(linear, right top, right top, color-stop(0%, rgba(186, 57, 83, 1)), color-stop(100%, rgba(186, 57, 83, 0.6965161064425771)));
  background: -webkit-linear-gradient(right, rgba(186, 57, 83, 1) 0%, rgba(186, 57, 83, 0.6965161064425771) 100%);
  background: -o-linear-gradient(right, rgba(186, 57, 83, 1) 0%, rgba(186, 57, 83, 0.6965161064425771) 100%);
  background: -ms-linear-gradient(right, rgba(186, 57, 83, 1) 0%, rgba(186, 57, 83, 0.6965161064425771) 100%);
  background: linear-gradient(to right, rgba(186,57,83,1) -2%, rgba(186, 57, 83, 0.6965161064425771) 111%);
  z-index: -1;
  opacity: 0;
  //transform: translateX(-100%);
  transition: all 0.6s ease-in-out;
}

.button-portfolio:hover:after {
  opacity: 1;
  //transform: translateX(0);
}

.container-separator-line {
  width: 100%;
  height: 2px;
  background: rgb(51, 152, 57);
  background: linear-gradient(122deg, rgba(51, 152, 57, 1) 31%, rgba(7, 6, 7, 1) 77%);
}

.container-support {
  background-color: #010101;
  position: relative;
  padding: 5% 0;
  z-index: 5;
  display: block;
  text-align: center;
}

.container-support-titles {
  position: relative;
  width: 40%;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0 auto;
  top: -6%;
  overflow: hidden;
}

.container-support_title {
  color: #359b38;
  font-size: 4em;
  font-weight: 900;
}

.container-support_subtitle {
  background: rgb(242, 242, 242);
  background: linear-gradient(153deg, #f2f2f2 72%, #c2a24d 89%);
  -webkit-background-clip: text;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 1px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.container-support-connections {
  position: relative;
  max-width: 2100px;
  width: 80%;
  margin: 0 auto;
  overflow: hidden;
}

.supportArchitects {
  display: block;
  max-height: 400px;
  padding: 20% 0;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 80%;
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: row;
}

.container-support-grid {
  min-width: 40em;
  min-height: 20em;
  z-index: 101;
  border: double .1em transparent;
  border-radius: 16px;
  color: #FFF;
  background-image: linear-gradient(#000000, #000000),
  linear-gradient(125deg, #000000, #c9c0da, #2a8db9);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.container-support-architects {
  display: inline-flex;
  padding: 10vh 15vh;
}

#container-support-architects-title {
  color: #c5c5c6;
  font-weight: 900;
  letter-spacing: 2px;
  text-align: center;
}

.target {
  color: #c5c5c6;
}

#container-support-architects-title, .target {
  font-size: 45px;
}

.container-support-text-grid {
  max-width: 1400px;
  width: 80%;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.container-support-text__content p {
  padding: 7vh 0;
  font-size: 17pt;
  line-height: 1.6em;
  background: #c5c5c6;
  background: linear-gradient(143deg, #c5c5c6 51%, #2a732c 61%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.container-support__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.button-learn-more {
  display: inline-block;
  border-radius: 99px;
  z-index: 5;
  margin: 0;
  position: relative;
  color: #fafafa;
  padding: 8px 60px;
}

.button-learn-more:before,
.button-learn-more:after {
  content: ' ';
  position: absolute;
  border-radius: 99px;
}

.button-learn-more:before {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
  background: #040404;
  background: -moz-linear-gradient(right, #040404 0%, #2a7c2e 100%);
  background: -webkit-gradient(linear, right top, right top, color-stop(0%, #040404), color-stop(100%, #2a7c2e));
  background: -webkit-linear-gradient(right, #040404 0%, #2a7c2e 100%);
  background: -o-linear-gradient(right, #040404 0%, #2a7c2e 100%);
  background: -ms-linear-gradient(right, #040404 0%, #2a7c2e 100%);
  background: linear-gradient(to right, #96044f -2%, #2a7c2e 111%);
}

.button-learn-more:after {
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  background-color: #040404;
  z-index: -1;
  opacity: 1;
  transition: all 0.6s ease-in-out;
}

.button-learn-more:hover:after {
  opacity: 0;
}


@media only screen and (max-width: 1990px) {
  .button-request__content {
    padding: 10px 80px;
  }
  .container-design-flume__block > :first-child {
   width:40%;

  }
  .container-design-flume__block > :last-child {
    width: 40%;

    margin-left: 0;
  }
  .button-portfolio {
    left: 18%;
  }

  .container-support-architects {
    padding: 14vh 23vh;
  }
  .container-design-titles {
    width: 28%;
  }
}

@media only screen and (max-width: 1880px) {
  .container-design-flume__block > :last-child {
    width: 40%;
  }
  .container-design-btn_portfolio {
    left: 49%;
  }
}

@media screen and (max-width: 1700px) {
  .button-portfolio {
    left: 19%;
  }
}

@media only screen and (max-width: 1440px) {
  .textAndImage__content {
    font-size: 17px;
  }
  .button-request__content {
    padding: 10px 50px;
  }
  .container-design-flume__block > :first-child {
  }
  .container-design-flume__block > :last-child {
    width: 40%;

  }
  .button-portfolio {
    padding: 5px 70px 5px 70px;
    left: 27%;
  }
  .container-design-btn_portfolio {
    left: 38%;
  }
  .grid-container-button-portfolio {
    width: 46%;
  }
  .container-support {

  }
  .container-design-flume__block-textAndButton p,
  .button-portfolio {
    font-size: 1.2em;
  }
  .container-support__content {
    height: 75vh;
  }
  .container-design-titles {
    font-size: 3.5rem;
    width: 34%;
  }
  .container-design_subtitle {
    padding-right: 57%;
    font-size: 2rem;
  }
  .container-support-grid {
    min-width: 20em;
    min-height: 17em;
  }
  .container-support-architects {
    padding: 12vh 17vh;
  }
}

@media only screen and (max-width: 1024px) {
  .container-support {

  }
  .container-separator-line {
    top: -3%;
  }
  .container-support_subtitle,
  .container-support-text__content p {
    font-size: 1.2em;
  }
  .container-support_title {
    font-size: 3.5em;
  }
  .button-request__content {
    padding: 8px 60px;
    font-size: 17px;
  }
  .textAndImage__block > :first-child {
    margin-top: 0;
    padding-right: 7%;
  }
  .button-request-line {
    width: 7em;
  }
  .container-design-titles {
    width: 49%;
  }
  .container-design-flume__block > :first-child {
    position: relative;
    left: 15%;

  }
  .container-design-flume__block > :last-child {
    width: 40%;
  }
  .button-portfolio {
    left: 19%;
  }
  .container-design_text {
    padding-top: 41em;
    font-size: 1.2em;
  }
  .supportArchitects {
    max-height: 20em;
  }
  #container-support-architects-title {
    font-size: 36px;
  }
  .target {
    font-size: 36px;
  }
  .container-support-architects {
    padding: 11vh 13vh;
  }
  .container-support-grid {
    min-width: 15em;
    min-height: 15em;
  }
}

@media only screen and (max-width: 768px) {

  .container-design-flume{
    height: 80vh;
  }
  .supportArchitects {
    background-size: 100%;
  }
  .container-separator-line {
    top: -7%;
  }
  .grid-container-textAndImage {
    width: 94%;
  }
  .textAndImage__title {
    font-size: 32pt;
  }
  .textAndImage__content {
    font-size: 15px;
  }
  .container-design-titles {

    width: 63%;
  }
  .container-design-flume__block-textAndButton p,
  .button-portfolio,
  .container-support-text__content p {
    font-size: 1em;
  }
  .container-design_title {
    font-size: 40px;
  }
  .container-design_subtitle {
    font-size: 22px;
  }
  .container-design_subtitle {
    padding-right: 48%;
  }
  .container-support-titles {
    width: 50%;
  }
  .container-design-flume__block > :first-child {
    margin-top: 0;
  }
  .container-design-flume__block > :last-child {

     }
  .button-portfolio {
    left: 21%;
  }
  .textAndImage__block {
    width: 73em;
  }
  .container-support_title {
    font-size: 3em;
  }
  .container-support_subtitle {
    font-size: 1em;
  }
}

@media only screen and (max-width: 600px) {
  .container-support-grid {
    min-width: 10em;
    min-height: 10em;
  }
  .container-support-architects {
    padding: 9vh 9vh;
  }
  .container-design-flume__block > :first-child {

  }

}

@media only screen and (max-width: 500px) {
  .laptop-slider-grid .laptop-slider-content__title{
    margin: 0 0 12vh 0;
    font-size: 50px;
  }
  .container-design-flume__block > :last-child {
    width: 100%;
  }
  .grid-container-fromLeft{
    background-size: cover;
  }
  .textAndImage {
    padding: 30px 0.25em;
    max-height: 100%;
  }
  .container-support-text-grid {
    width: 100%;
  }
  .textAndImage__block {
    -ms-flex-direction: column;
    flex-direction: column;
    width: 28em;

    .textAndImage_block-text {
      p {
        padding: 0 20px;
      }
    }
  }
  .button-request-line {
    display: none;
  }
  .textAndImage__block > :first-child {
    width: calc(76% - 2px);
    left: 0;
    font-size: 16px;
    color: darkgrey;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
  }
  .textAndImage__block > :last-child {
    background: url("../../assets/screens-moble-shadow.webp");
    width: calc(129% - 2px);
    height: 83vh;
    z-index: 1024;
    transform: scale(.8);
  }
  .textAndImage__title {
    margin-bottom: 15px;
    text-align: center;
    background: #2d98c8;
    background: linear-gradient(125deg, #2d98c8 62%, #1a3041 71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 37pt;
  }
  .button-request,
  .container-design-btn_portfolio {
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    text-decoration: none;
  }
  .button-request__content {
    margin-top: 44px;
    width: 18em;
    font-size: 16px;
  }
  .button-portfolio {
    width: 18em;
    margin-top: 15px;
    font-size: 16px;
  }
  .container-design-flume__block {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

  }
  .container-design_subtitle {
    /*padding-left: 41%;
    padding-right: 0;*/
    font-size: 22pt;
  }
  .container-design_title {
    font-size: 26pt;
  }
  .container-design-titles {
    width: 82%;
  }
  .container-design-flume__block-textAndButton p {
    display: none;
  }
  .container-design_text {
    padding-top: 1em;
    font-size: 1.2em;
  }

  .container-design-flume__block > :first-child {
    //width: calc(32em - 2px);
    left: -28%;
  }
  .container-design-flume__block > :last-child {

  }
  .container-separator-line {
    top: 70%;
  }
  .supportArchitects {
    max-height: 18em;
  }

  .container-support_subtitle {
    font-size: 11px;
  }
  .container-support-text__content p {
    font-size: 17px;
    background: linear-gradient(125deg, #c5c5c6 42%, #2a732c 66%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }
  .container-support-titles {
    width: 100%;
    top: 0;
  }
  .container-support-line {
    display: none;
  }
  .container-support_subtitle {
    font-size: 16pt;
    background: linear-gradient(153deg, #f2f2f2 81%, #c2a24d 88%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }
  .container-support-connections {
    margin: 0 auto;
    width: 100%;
  }
  .button-learn-more {
    margin-bottom: 20px;
    width: 18em;
    font-size: 16pt;
  }
  .container-support_title {
    font-size: 37pt;
  }
}

@media only screen and (max-width: 500px) {
  .container-design-flume {
    height: 89vh;
  }
  .grid-container-textAndImage {
    width: 100%;
  }
  .textAndImage__block {
       width: 24em;
     }
  .textAndImage__block > :first-child {
    width: calc(100% - 2px);
  }
  .button-request__content, .button-portfolio, .button-learn-more {
    width: 275px;
    padding: 10px;
  }
  .container-design-titles {
    width: 100%;
  }
  .container-design_title{
  //  font-size: 24pt;
  }
  .container-design_subtitle {
    font-size: 19pt;
  }
  .container-design-flume__block > :last-child {
  }
  .container-support_subtitle {
    font-size: 20px;
  }

}

</style>
